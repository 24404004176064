.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*Custom style*/
:root {
  --main-color: #4d4d4d;

  --main-button: #505e69;
  --main-button-shadow: #707e8c;
  --main-button-disabled: #2f373d;

  --red: #700c04;
  --red-shadow: #a27e7d;
  --red-highlight: #ff0020;
  --red-dark: #350802;
  /*--red-gradient: rgba(113, 20, 51, 0.82);*/
  --white: #ffffff;
  --gray: #d9d9d9;
  --bg-gray: #f5f5f5;
  --black: #000000;
  --black-icon: #0f0f0f;

  --gray-ticket: #818181;
  --gray-ticket-line: #f4f2f5;
  --blue-ticket: #455a64;
  --gray-ticket-date: #aaa4be;
  --white-ticket: #f5f5f5;
  --red-ticket-price: #d84130;
  --white-price: #b9b5c4;

  /*--red-transport: #FF0020;*/
  /*--sky-hotel: #00FFF0;*/
  /*--yellow-eat: #FFC700;  */
  --flight: #4d4d4d;
  --train: #98a9b3;
  --car: #818181;
  --hotel: #505e69;
  --eat: #c9c9c9;
}

.bg {
  /*height: 100vh;*/
  /*width: 100vw;*/
  background-color: var(--white);
}
.bg-gray {
  background-color: var(--bg-gray) !important;
}

.bg-gray-content {
  background-color: #f3f3f3 !important;
  padding: 1rem 0.5rem 1rem 0.5rem;
  margin-bottom: 1rem;
}

.bg-gray-content {
  background-color: #f3f3f3 !important;
  padding: 1rem 0.5rem 1rem 0.5rem;
  margin-bottom: 1rem;
}

.img-content {
  width: 60% !important;
  margin: auto;
}

.upload-img-preview {
  width: 10rem;
  height: 10rem;
  object-fit: cover;
  padding-right: 1rem;
}

.upload-img-preview-286 {
  width: 286px;
  height: 286px;
  object-fit: cover;
  margin-right: 1rem;
}

.upload-img-icon-bg-286 {
  min-width: 286px !important;
  min-height: 286px !important;
  margin-right: 1rem;
  background-color: #c4c4c4;
  border-radius: 10px;
  border: unset !important;
  cursor: pointer;
}

.upload-img-icon-bg-195 {
  min-width: 195px !important;
  min-height: 195px !important;
  margin-right: 1rem;
  background-color: #c4c4c4;
  border-radius: 10px;
  border: unset !important;
  cursor: pointer;
}

.upload-img-icon {
  width: 100px !important;
  height: 100px !important;
  /*margin: 90px auto 0 auto;*/
}

.upload-img-icon-195 {
  width: 70px !important;
  height: 70px !important;
  /*margin: 90px auto 0 auto;*/
}

.upload-img-preview-195 {
  width: 195px;
  height: 195px;
  object-fit: cover;
  margin-right: 1rem;
}

.show-img-preview {
  width: 327px;
  height: 327px;
  object-fit: cover;
  margin-right: 2rem;
  /*padding-right: 2rem;*/
  /*margin-left: -30%;*/
}

.show-img-preview-195 {
  width: 195px;
  height: 195px;
  object-fit: cover;
  margin-right: 2rem;
  /*padding-right: 2rem;*/
  /*margin-left: -30%;*/
}

.show-day-img {
  margin-left: -18%;
  z-index: 1;
}

.logo-img {
  width: 3.5rem;
  /*padding: .2rem;*/
  /*background-color: var(--white);*/
  /*border-radius: 50%;*/
  filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.25));
}
.profile-img {
  width: 2.5rem;
  /*padding: .2rem;*/
  background-color: var(--white);
  border-radius: 50%;
  filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.25));
  position: absolute;
  margin: -0.5rem 1rem 0 1rem;
}

.profile-img-post {
  width: 2.5rem;
  background-color: var(--white);
  border-radius: 50%;
  filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.25));
}

.post-img {
  width: 80% !important;
  height: auto;
  cursor: pointer;
}

.card-border-60 {
  padding: 10px;
  border-color: transparent !important;
  border-radius: 60px !important;
  filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.15));
}

.nav-logout {
  text-align: right;
  margin-right: -2rem;
  margin-top: 1rem;
}
.nav-logout-mobile {
  text-align: right;
  margin-top: 1rem;
}

.profile-img-big {
  width: 10rem;
  /*padding: .2rem;*/
  background-color: var(--white);
  border-radius: 50%;
  filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.25));
}

.timeline-profile-img {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.2rem;
  margin-right: 5px;
  background-color: var(--white);
  border-radius: 50%;
  filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.25));
}

.timeline-profile-text {
  margin-top: 6px;
  font-weight: bold;
  cursor: pointer;
  color: #2586cc;
}

.timeline-profile-date {
  margin-top: 6px;
  font-weight: bold;
  /* cursor: pointer; */
  color: #c4c4c4;
}

.travel-img {
  width: 2.8rem;
  height: 2.8rem;
  background-color: var(--white);
  border-radius: 50%;
  object-fit: cover;
  margin-top: 5px;
  margin-left: 5px;
}

.circle-border {
  /*box-shadow: 0 4px 25px rgba(0, 0, 0, 0.25);*/
  border-radius: 29px !important;
}
.friend-invite-box {
  width: 80% !important;
  /*margin: 1.5rem 0 1.5rem 0!important;*/
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.25);
  border-radius: 29px !important;
}

.circle-border-search-bar {
  /*box-shadow: 0 4px 25px rgba(0, 0, 0, 0.25);*/
  border-radius: 29px !important;
  border: 3.2px solid var(--black-icon) !important;
}
.pt-half1 {
  padding-top: 0.25rem;
}

.pt-point75 {
  padding-top: 0.75rem;
}

.circle-border-google {
  height: 5rem !important;
  width: 5rem !important;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.25);
  border-radius: 50px !important;
  margin: 1rem 0 0 2rem;
  padding: 0 0 0 1.2rem !important;
}

.circle-border-small {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 40px !important;
  /*padding: 0.5rem 0.5rem 0.15rem 0.5rem!important;*/
  padding: 0 0.5rem 0 0 !important;
}

.text-left {
  text-align: left !important;
}

.circle-border-small-login-mobile {
  z-index: 999;
  position: fixed !important;
  bottom: 4px !important;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 59px !important;
  padding: 0 0.5rem 0.15rem 0 !important;
}

.circle-none-border-small-post-mobile {
  z-index: 999;
  position: fixed !important;
  bottom: 4px !important;
  background-color: transparent !important;
  border: unset !important;
  /* padding: 0 0.5rem 0.15rem 0!important; */
  width: 40vw;
  margin: 0 0 0 -3rem !important;
}

.text-login-mobile {
  text-align: left !important;
  padding-left: 3rem !important;
  line-height: 1;
}

.red-circle-border {
  color: var(--main-color) !important;
  background-color: var(--white);
  width: 2rem;
  height: 2rem;
  border-radius: 29px !important;
  padding: 0.2rem;
  border: 3.2px solid var(--black-icon);
  margin: 0.2rem 0.5rem 0 0.3rem;
  /*box-shadow: 0 4px 25px rgba(0, 0, 0, 0.25);*/
}

.red {
  color: var(--red);
}

.red-highlight {
  color: var(--red-highlight);
}

.right-margin {
  right: 0;
  margin-right: 1rem !important;
  z-index: 10;
}

.right-padding-input {
  padding-right: 2.5rem !important;
}

.input-no-outline {
  padding-left: 0 !important;
}
/*textarea{*/
/*  padding-left: 0!important;*/
/*}*/
/*textarea:focus {*/
/*  outline: none !important;*/
/*  border-color: transparent!important;*/
/*  box-shadow: unset!important;*/
/*}*/
.input-no-outline:focus {
  outline: none !important;
  border-color: transparent !important;
  box-shadow: unset !important;
  /*border-color: var(--main-button-shadow)!important;*/
  /*box-shadow: 0 0 10px var(--main-button-shadow)!important;*/
}

input:focus {
  outline: none !important;
  /*border-color: transparent!important;*/
  /*box-shadow: unset!important;*/
  border-color: var(--main-button-shadow) !important;
  box-shadow: 0 0 10px var(--main-button-shadow) !important;
}

.camera-icon-trip {
  font-size: 28px;
  background-color: transparent;
  border: unset !important;
  /*cursor: pointer!important;*/
}

.camera-icon-position {
  padding: 6.2rem 6rem 0 6rem;
  background-color: var(--gray);
}

.line-height-down {
  line-height: 0.5;
}

.margin-top-shortcut {
  margin-top: 0.7rem;
  margin-bottom: 0.6rem;
  /*margin-top: 1.2rem;*/
  /*margin-left: .2rem;*/
}

.travel-box {
  /*max-width: 13.5rem!important;*/
  /*min-width: 13.2rem!important;*/
  width: 13rem !important;
  padding: 1rem;
  cursor: pointer;
}

.travel-blog-list-150 {
  width: 100%;
  height: 150px;
  object-fit: cover;
  padding: 2px !important;
}

.center-page {
  /*height: 100vh;*/
  margin-top: 20vh;
  /*margin-left: 10vw;*/
  /*margin-right: 10vw;*/
}

.underline {
  color: var(--main-color);
  position: relative;
  display: inline-block;
  margin-bottom: 2rem;
}

.underline:after {
  content: "";
  position: absolute;
  width: 3rem;
  height: 0.35rem;
  background-color: var(--main-color);
  margin: 3.4rem 0 0 0;
  left: 0;
  border-radius: 10px;
}

/*Ticket Search*/
.cardWrap {
  /*width: 27em;*/
  width: 100%;
  margin: 3em auto;
  color: #fff;
  font-family: sans-serif;
}

.card-ticket {
  /*background: linear-gradient(to bottom, #e84c3d 0%, #e84c3d 26%, #ecedef 26%, #ecedef 100%);*/
  background-color: #fff;
  height: 11em;
  float: left;
  position: relative;
  padding: 1em;
  margin-top: 100px;
  /*box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);*/
}

.cardLeft {
  /*border-top-left-radius: 8px;*/
  /*border-bottom-left-radius: 8px;*/
  border-top-left-radius: 29px !important;
  border-bottom-left-radius: 29px !important;

  /*border-right: 4px dotted blue!important;*/

  /*width: 16em;*/
  width: 60%;
  height: 12em !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.25);
}

.cardRight {
  /*width: 6.5em;*/
  height: 12em !important;
  width: 40%;
  border-left: 0.18em dashed #fff;
  /*border-top-right-radius: 8px;*/
  /*border-bottom-right-radius: 8px;*/
  border-top-right-radius: 29px !important;
  border-bottom-right-radius: 29px !important;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.25);

  border-left: 3px dashed var(--gray-ticket-line) !important;
  border-right: unset !important;
  border-bottom: unset !important;
  border-top: unset !important;
  /*border-left: unset!important;*/
}
.cardRight:before {
  content: "";
  position: absolute;
  display: block;
  /*width: 0.9em;*/
  /*height: 0.9em;*/
  width: 35px;
  height: 20px;

  border-bottom-left-radius: 35px;
  border-bottom-right-radius: 35px;
  /*border: 1px solid gray;*/
  /*border-bottom: 0;*/

  background: var(--white);
  /*border-radius: 50%;*/
  /*left: -0.5em;*/
  left: -18px;
  box-shadow: inset 0 -1px 2px rgba(0, 0, 0, 0.1),
    2px -8px 10px rgb(255 255 255);
  /*box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.25);*/
  /*box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);*/
}
.cardRight:after {
  content: "";
  position: absolute;
  display: block;
  /*width: 0.9em;*/
  /*height: 0.9em;*/
  width: 35px;
  height: 20px;

  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
  /*border: 1px solid gray;*/
  /*border-bottom: 0;*/

  background: var(--white);
  /*border-radius: 50%;*/
  /*left: -0.5em;*/
  left: -18px;
  bottom: -1px !important;
  box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.25),
    2px 8px 10px rgb(255 255 255);
  /*box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);*/
}
.cardRight:before {
  top: -0.4em;
}
.cardRight:after {
  bottom: -0.4em;
}

.title {
  color: var(--gray-ticket);
}

.city {
  font-weight: 500 !important;
  line-height: 20px !important;
  color: var(--blue-ticket);
}

.date {
  font-weight: 500 !important;
  line-height: 20px !important;
  color: var(--gray-ticket-date);
}

.button-ticket {
  border-radius: 21px !important;
  --bs-btn-color: var(--white-ticket) !important;
  --bs-btn-bg: var(--main-button) !important;
  --bs-btn-border-color: var(--main-button) !important;
  --bs-btn-hover-color: var(--white-ticket) !important;
  --bs-btn-hover-bg: var(--main-button-shadow) !important;
  --bs-btn-hover-border-color: var(--main-button-shadow) !important;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: var(--white-ticket) !important;
  --bs-btn-active-bg: var(--main-button) !important;
  --bs-btn-active-border-color: var(--main-button) !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--white-ticket) !important;
  --bs-btn-disabled-bg: var(--main-button-disabled) !important;
  --bs-btn-disabled-border-color: var(--main-button-disabled) !important;
}

.sarch-btn {
  background: transparent;
  border: unset;
  /*--bs-btn-color: transparent!important;*/
  /*--bs-btn-bg: transparent!important;*/
  /*--bs-btn-border-color: transparent!important;*/
  /*--bs-btn-hover-color: transparent!important;*/
  /*--bs-btn-hover-bg: transparent!important;*/
  /*--bs-btn-hover-border-color: transparent!important;*/
  /*!*--bs-btn-focus-shadow-rgb: 49, 132, 253;*!*/
  /*--bs-btn-focus-shadow-rgb: unset;*/
  /*--bs-btn-active-color: transparent!important;*/
  /*--bs-btn-active-bg: transparent!important;*/
  /*--bs-btn-active-border-color: transparent!important;*/
  /*!*--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);*!*/
  /*--bs-btn-active-shadow: unset;*/
  /*--bs-btn-disabled-color: transparent!important;*/
  /*--bs-btn-disabled-bg: transparent!important;*/
  /*--bs-btn-disabled-border-color: transparent!important;*/
}

.btn-no-button {
  /*margin-top: -5px;*/
  --bs-btn-color: var(--black) !important;
  --bs-btn-bg: transparent !important;
  --bs-btn-border-color: transparent !important;
  --bs-btn-hover-color: var(--black-icon) !important;
  --bs-btn-hover-bg: transparent !important;
  --bs-btn-hover-border-color: transparent !important;
  --bs-btn-focus-shadow-rgb: unset;
  --bs-btn-active-color: var(--black) !important;
  --bs-btn-active-bg: transparent !important;
  --bs-btn-active-border-color: transparent !important;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: var(--gray) !important;
  --bs-btn-disabled-bg: transparent !important;
  --bs-btn-disabled-border-color: transparent !important;
}

.button-join {
  border-radius: 30px !important;
  padding: 0 1rem 0 1rem !important;
  margin: 5px 0 5px 0;
  --bs-btn-color: var(--white-ticket) !important;
  --bs-btn-bg: var(--black) !important;
  --bs-btn-border-color: var(--black) !important;
  --bs-btn-hover-color: var(--white-ticket) !important;
  --bs-btn-hover-bg: var(--main-button-shadow) !important;
  --bs-btn-hover-border-color: var(--main-button-shadow) !important;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: var(--white-ticket) !important;
  --bs-btn-active-bg: var(--black) !important;
  --bs-btn-active-border-color: var(--black) !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--white-ticket) !important;
  --bs-btn-disabled-bg: var(--main-button-disabled) !important;
  --bs-btn-disabled-border-color: var(--main-button-disabled) !important;
}

.button-light {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 21px !important;
  --bs-btn-color: var(--main-color) !important;
  --bs-btn-bg: var(--white) !important;
  --bs-btn-border-color: var(--white-ticket) !important;
  --bs-btn-hover-color: var(--main-color) !important;
  --bs-btn-hover-bg: var(--white-ticket) !important;
  --bs-btn-hover-border-color: var(--white-ticket) !important;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: var(--main-color) !important;
  --bs-btn-active-bg: var(--gray-ticket) !important;
  --bs-btn-active-border-color: var(--white-ticket) !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--white-ticket) !important;
  --bs-btn-disabled-bg: var(--main-button-disabled) !important;
  --bs-btn-disabled-border-color: var(--main-button-disabled) !important;
}

.button-light-post {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 21px !important;
  --bs-btn-color: #000000 !important;
  --bs-btn-bg: #2a37ff !important;
  --bs-btn-border-color: #2a37ff !important;
  --bs-btn-hover-color: #fff !important;
  --bs-btn-hover-bg: rgba(42, 55, 255, 0.5) !important;
  --bs-btn-hover-border-color: var(--white-ticket) !important;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: var(--main-color) !important;
  --bs-btn-active-bg: var(--gray-ticket) !important;
  --bs-btn-active-border-color: var(--white-ticket) !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--white-ticket) !important;
  --bs-btn-disabled-bg: var(--main-button-disabled) !important;
  --bs-btn-disabled-border-color: var(--main-button-disabled) !important;
}

.button-google-login {
  padding-left: 1rem !important;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 21px !important;
  --bs-btn-color: var(--main-color) !important;
  --bs-btn-bg: var(--white) !important;
  --bs-btn-border-color: var(--white-ticket) !important;
  --bs-btn-hover-color: var(--main-color) !important;
  --bs-btn-hover-bg: var(--white-ticket) !important;
  --bs-btn-hover-border-color: var(--white-ticket) !important;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: var(--main-color) !important;
  --bs-btn-active-bg: var(--gray-ticket) !important;
  --bs-btn-active-border-color: var(--white-ticket) !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--white-ticket) !important;
  --bs-btn-disabled-bg: var(--main-button-disabled) !important;
  --bs-btn-disabled-border-color: var(--main-button-disabled) !important;
}

.button-booking {
  /* max-height: 48px;
  max-width: 70px; */
  max-height: 42px;
  min-height: 42px;
  /* max-height: 48px;
  max-width: 70px; */
  max-height: 42px;
  margin: 1rem 0 0 0;
  padding: 0 1rem 0 1rem !important;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 30px !important;
  --bs-btn-color: var(--white) !important;
  --bs-btn-bg: rgba(255, 0, 0, 1) !important;
  --bs-btn-border-color: rgba(255, 0, 0, 1) !important;
  --bs-btn-hover-color: var(--white) !important;
  --bs-btn-hover-bg: rgb(228, 2, 2) !important;
  --bs-btn-hover-border-color: rgb(228, 0, 0) !important;
  border-radius: 30px !important;
  --bs-btn-color: var(--white) !important;
  --bs-btn-bg: rgba(255, 0, 0, 1) !important;
  --bs-btn-border-color: rgba(255, 0, 0, 1) !important;
  --bs-btn-hover-color: var(--white) !important;
  --bs-btn-hover-bg: rgb(228, 2, 2) !important;
  --bs-btn-hover-border-color: rgb(228, 0, 0) !important;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  /*--bs-btn-active-color: #2586CC!important;*/
  /*--bs-btn-active-bg: var(--gray-ticket)!important;*/
  /*--bs-btn-active-border-color: var(--white-ticket)!important;*/
  /*--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);*/
  /*--bs-btn-disabled-color: var(--white-ticket)!important;*/
  /*--bs-btn-disabled-bg: var(--main-button-disabled)!important;*/
  /*--bs-btn-disabled-border-color: var(--main-button-disabled)!important;*/
}
/* Rectangle 4284 */
/* Rectangle 4284 */

.button-plus {
  /*margin-left: -1.4rem;*/
  /*position: absolute;*/
  /*bottom: 0;*/
  margin: -4rem 0 0 7rem;
  width: 50px !important;
  height: 50px !important;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 50px !important;
  --bs-btn-color: var(--main-color) !important;
  --bs-btn-bg: var(--white) !important;
  --bs-btn-border-color: var(--white-ticket) !important;
  --bs-btn-hover-color: var(--main-color) !important;
  --bs-btn-hover-bg: var(--white-ticket) !important;
  --bs-btn-hover-border-color: var(--white-ticket) !important;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: var(--main-color) !important;
  --bs-btn-active-bg: var(--gray-ticket) !important;
  --bs-btn-active-border-color: var(--white-ticket) !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--white-ticket) !important;
  --bs-btn-disabled-bg: var(--main-button-disabled) !important;
  --bs-btn-disabled-border-color: var(--main-button-disabled) !important;
}

.ticket-red-price {
  color: var(--red-ticket-price);
}

.price {
  color: var(--white-price);
}

.icon-city {
  color: var(--black);
  background-color: var(--main-button);
  border-radius: 10px;
  width: 30px;
  height: 30px;
  padding: 5px;
}

/*.title, .name, .seat, .time {*/
/*  text-transform: uppercase;*/
/*  font-weight: normal;*/
/*}*/
/*.title h2, .name h2, .seat h2, .time h2 {*/
/*  font-size: 0.9em;*/
/*  color: #525252;*/
/*  margin: 0;*/
/*}*/
/*.title span, .name span, .seat span, .time span {*/
/*  font-size: 0.7em;*/
/*  color: #a2aeae;*/
/*}*/

/*.title {*/
/*  !*margin: 2em 0 0 0;*!*/
/*  margin: 1em 0 0 0;*/
/*}*/

/*.name, .seat {*/
/*  margin: 0.7em 0 0 0;*/
/*}*/

/*.time {*/
/*  margin: 0.7em 0 0 1em;*/
/*}*/

/*.seat, .time {*/
/*  float: left;*/
/*}*/

/*.eye {*/
/*  position: relative;*/
/*  width: 2em;*/
/*  height: 1.5em;*/
/*  background: #fff;*/
/*  margin: 0 auto;*/
/*  border-radius: 1em/0.6em;*/
/*  z-index: 1;*/
/*}*/
/*.eye:before, .eye:after {*/
/*  content: "";*/
/*  display: block;*/
/*  position: absolute;*/
/*  border-radius: 50%;*/
/*}*/
/*.eye:before {*/
/*  width: 1em;*/
/*  height: 1em;*/
/*  background: #e84c3d;*/
/*  z-index: 2;*/
/*  left: 8px;*/
/*  top: 4px;*/
/*}*/
/*.eye:after {*/
/*  width: 0.5em;*/
/*  height: 0.5em;*/
/*  background: #fff;*/
/*  z-index: 3;*/
/*  left: 12px;*/
/*  top: 8px;*/
/*}*/

/*.number {*/
/*  text-align: center;*/
/*  text-transform: uppercase;*/
/*}*/
/*.number h3 {*/
/*  color: #e84c3d;*/
/*  margin: 0.9em 0 0 0;*/
/*  font-size: 2.5em;*/
/*}*/
/*.number span {*/
/*  display: block;*/
/*  color: #a2aeae;*/
/*}*/

/*.barcode {*/
/*  height: 2em;*/
/*  width: 0;*/
/*  margin: 1.2em 0 0 0.8em;*/
/*  box-shadow: 1px 0 0 1px #343434, 5px 0 0 1px #343434, 10px 0 0 1px #343434, 11px 0 0 1px #343434, 15px 0 0 1px #343434, 18px 0 0 1px #343434, 22px 0 0 1px #343434, 23px 0 0 1px #343434, 26px 0 0 1px #343434, 30px 0 0 1px #343434, 35px 0 0 1px #343434, 37px 0 0 1px #343434, 41px 0 0 1px #343434, 44px 0 0 1px #343434, 47px 0 0 1px #343434, 51px 0 0 1px #343434, 56px 0 0 1px #343434, 59px 0 0 1px #343434, 64px 0 0 1px #343434, 68px 0 0 1px #343434, 72px 0 0 1px #343434, 74px 0 0 1px #343434, 77px 0 0 1px #343434, 81px 0 0 1px #343434;*/
/*}*/

/*Ticket*/
.cardWrapTicket {
  width: 100%;
  margin: 3em auto;
  color: #fff;
  font-family: sans-serif;
}

.cardTop {
  border-top-left-radius: 29px !important;
  border-top-right-radius: 29px !important;
  width: 100%;
  /*height: 12em !important;*/
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.25);

  padding: 1em;
  /*margin-top: 100px;*/
}

.cardMiddle {
  width: 100%;
  border-left: 0.18em dashed #fff;

  /*box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.25);*/
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.25);

  border-top: 3px dashed var(--gray-ticket-line) !important;
  border-bottom: 2px dashed var(--gray-ticket-line) !important;
  border-right: unset;
  border-left: unset;

  padding: 1em;
}

.cardBottom {
  width: 100%;
  border-left: 0.18em dashed #fff;

  border-bottom-left-radius: 29px !important;
  border-bottom-right-radius: 29px !important;

  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.25);

  padding: 1em;
}

.cardMiddle:before {
  content: "";
  position: absolute;
  display: block;

  width: 20px;
  height: 35px;

  border-top-right-radius: 35px;
  border-bottom-right-radius: 35px;

  background: var(--white);

  left: -1px;
  top: -1rem;
  box-shadow: inset -4px 2px 4px rgba(0, 0, 0, 0.1),
    -10px 2px 10px rgb(255 255 255);
}
.cardMiddle:after {
  content: "";
  position: absolute;
  display: block;

  width: 20px;
  height: 35px;

  border-top-left-radius: 35px;
  border-bottom-left-radius: 35px;

  background: var(--white);
  right: -1px;
  top: -1rem !important;
  box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.25), 4px 0 10px rgb(255 255 255);
}

.cardBottom:before {
  content: "";
  position: absolute;
  display: block;

  width: 20px;
  height: 35px;

  border-top-right-radius: 35px;
  border-bottom-right-radius: 35px;

  background: var(--white);

  left: -1px;
  top: -1rem;
  box-shadow: inset -4px 2px 4px rgba(0, 0, 0, 0.1),
    -10px 2px 10px rgb(255 255 255);
}
.cardBottom:after {
  content: "";
  position: absolute;
  display: block;

  width: 20px;
  height: 35px;

  border-top-left-radius: 35px;
  border-bottom-left-radius: 35px;

  background: var(--white);
  right: -1px;
  top: -1rem !important;
  box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.25), 4px 0 10px rgb(255 255 255);
}

.text-ticket {
  color: var(--black);
  letter-spacing: -0.02em !important;
  line-height: 10px !important;
  font-weight: 400 !important;
}

.data-ticket {
  color: var(--black);
  letter-spacing: -0.02em !important;
  line-height: 10px !important;
  font-weight: 600 !important;
}

.data-ticket-red {
  color: var(--main-button);
  letter-spacing: -0.02em !important;
  line-height: 10px !important;
  font-weight: 600 !important;
}

.trip {
  color: var(--black);
  letter-spacing: -0.02em !important;
  line-height: 10px !important;
  font-weight: normal !important;
  padding: 12px !important;
}

.trip.active {
  background-color: var(--main-button);
  color: var(--white);
  letter-spacing: -0.02em !important;
  line-height: 10px !important;
  font-weight: normal !important;
  padding: 12px !important;
  border-radius: 14.5px;
}

.text-right {
  text-align: right !important;
}

.data-ticket-thick {
  color: var(--black);
  font-weight: bold !important;
  font-size: 40px !important;
}

.qrcode {
  width: 100%;
  height: auto;
}

.time-ticket {
  background-color: var(--main-button);
  color: var(--white);
  border-radius: 8px;
  width: 75px;
  height: 27px;
  padding: 12px !important;
  margin-bottom: 1rem !important;
  margin-top: -0.5rem !important;
}

.airplan-icon {
  color: var(--black);
  margin-top: -45%;
  margin-left: 100%;
}
/*.cardBottom:before {*/
/*  top: -0.4em;*/
/*}*/
/*.cardBottom:after {*/
/*  bottom: -0.4em;*/
/*}*/

/*.title{*/
/*  color: var(--gray-ticket);*/
/*}*/

/*.city{*/
/*  font-weight: 500!important;*/
/*  line-height: 20px!important;*/
/*  color: var(--blue-ticket);*/
/*}*/

/*.date{*/
/*  font-weight: 500!important;*/
/*  line-height: 20px!important;*/
/*  color: var(--gray-ticket-date);*/
/*}*/

/*.button-ticket{*/
/*  --bs-btn-color: var(--white-ticket)!important;*/
/*  --bs-btn-bg: var(--red)!important;*/
/*  --bs-btn-border-color: var(--red)!important;*/
/*  --bs-btn-hover-color: var(--white-ticket)!important;*/
/*  --bs-btn-hover-bg: var(--red-shadow)!important;*/
/*  --bs-btn-hover-border-color: var(--red-shadow)!important;*/
/*  --bs-btn-focus-shadow-rgb: 49, 132, 253;*/
/*  --bs-btn-active-color: var(--white-ticket)!important;*/
/*  --bs-btn-active-bg: var(--red)!important;*/
/*  --bs-btn-active-border-color: var(--red)!important;*/
/*  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);*/
/*  --bs-btn-disabled-color: var(--white-ticket)!important;*/
/*  --bs-btn-disabled-bg: var(--red-dark)!important;*/
/*  --bs-btn-disabled-border-color: var(--red-dark)!important;*/
/*}*/

/*.ticket-red-price{*/
/*  color: var(--red-ticket-price);*/
/*}*/

/*.price{*/
/*  color: var(--white-price);*/
/*}*/

/*.icon-city{*/
/*  color: var(--black);*/
/*  background-color: var(--red);*/
/*  border-radius: 10px;*/
/*  width: 30px;*/
/*  height: 30px;*/
/*  padding: 5px;*/
/*}*/

.icon-switch {
  z-index: 2;
  color: var(--black);
  background-color: var(--main-button);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 5px;
  margin-top: 5.8rem;
}

.icon-location-group {
  z-index: 2;
  color: var(--white);
  background-color: var(--main-button);
  width: 30px;
  height: 60%;
  padding: 6.5px;
  margin-top: 3rem;
  border-radius: 14.5px;
}

.icon-pin {
  z-index: 2;
  width: 18px;
  height: auto;
  margin-top: 4.4rem;
}

.line-dot-y {
  z-index: 2;
  height: 3.2rem;
  position: absolute;
  background-color: transparent;
  border-left: 3px dashed var(--white) !important;
  margin-top: -5rem;
  margin-left: 0.5rem;
}

.line-dot-x {
  border-bottom: 3px dashed var(--gray-ticket-line) !important;
  padding-bottom: 3rem !important;
}

.text-gray {
  color: var(--gray-ticket-date);
}

.gray {
  color: var(--main-color);
}

/*Timeline*/
/*.timeline-day{*/
/*  color: var(--black)!important;*/
/*}*/

/*.timeline-day.active{*/
/*  position:relative!important;*/
/*  display:inline-block!important;*/
/*  margin-bottom: 1rem!important;*/
/*}*/

/*.timeline-day.active:after {*/
/*  content: ""!important;*/
/*  position: absolute!important;*/
/*  width: 2.5rem!important;*/
/*  height: 0.35rem!important;*/
/*  background-color: var(--red)!important;*/
/*  margin: 2.2rem 0 0 0!important;*/
/*  left: 1rem!important;*/
/*  border-radius: 10px!important;*/

/*}*/

.icon-transport-flight {
  color: var(--black);
  background-color: var(--flight);
  border-radius: 10px;
  width: 30px;
  height: 30px;
  padding: 5px;
}

.icon-transport-car {
  color: var(--black);
  background-color: var(--car);
  border-radius: 10px;
  width: 30px;
  height: 30px;
  padding: 5px;
}

.icon-transport-train {
  color: var(--black);
  background-color: var(--train);
  border-radius: 10px;
  width: 30px;
  height: 30px;
  padding: 5px;
}

.icon-eat {
  color: var(--black);
  background-color: var(--eat);
  border-radius: 10px;
  width: 30px;
  height: 30px;
  padding: 5px;
}

.icon-hotel {
  color: var(--black);
  background-color: var(--hotel);
  border-radius: 10px;
  width: 30px;
  height: 30px;
  padding: 5px;
}

.data-timeline {
  color: var(--black);
  letter-spacing: -0.02em !important;
  font-weight: 600 !important;
}

.text-timeline {
  color: var(--black);
  letter-spacing: -0.02em !important;
  font-weight: 400 !important;
}

.line-timeline {
  /*background-image: linear-gradient(var(--black), var(--white))!important;*/
  /*height: 94%!important;*/
  background-color: var(--black);
  height: 100% !important;
  width: 3px !important;
  margin-left: 70% !important;
  margin-top: 3rem !important;
}

.line-timeline:before {
  content: "";
  /*position: absolute;*/
  display: block;
  background: var(--black);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-left: -0.5rem !important;
  margin-top: -0.1rem !important;
}

.line-timeline-last-child {
  background-image: linear-gradient(var(--black), var(--white)) !important;
  height: 94% !important;
  width: 3px !important;
  margin-left: 70% !important;
  margin-top: 3rem !important;
}

.line-timeline-last-child:before {
  content: "";
  /*position: absolute;*/
  display: block;
  background: var(--black);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-left: -0.5rem !important;
  margin-top: -0.1rem !important;
}

.line-col {
  z-index: 2;
}

/*.day-timeline:before {*/
/*  content: "";*/
/*  position: absolute;*/
/*  display: block;*/
/*  background: var(--black);*/
/*  width: 25px;*/
/*  height: 25px;*/
/*  border-radius: 50%;*/
/*  margin-left: -5.5%!important;*/
/*  !*margin-top: -.1rem!important;*!*/
/*}*/

.link-default {
  color: var(--black) !important;
  text-decoration: none !important;
}

.link-copied {
  padding: 0 !important;
  margin: 0 !important;
}

/* Remove browser defaults */
* {
  /*box-sizing: border-box;*/
  padding: 0;
  margin: 0;
}

/* Style App.js wrapper */
/*.App {*/
/*  width: 100vw;*/
/*height: 100vh;*/
/*display: flex;*/
/*align-items: center;*/
/*justify-content: center;*/
/*  overflow: hidden;*/
/*}*/

/* Tab Container */
.Tabs {
  width: 100%;
  height: auto;
  /*min-height: 400px;*/
  /*background: #053742;*/
  /*margin: 3.5rem auto 1.5rem;*/
  /*padding: 2rem 1rem;*/
  /*color: #E8F0F2;*/
  /*border-radius: 2rem;*/
}

.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  width: 100%;
  margin: 0 auto 0;
  /*margin: 0 auto 2rem;*/
  display: flex;
  align-items: start;
  /*align-items: center;*/
  justify-content: space-between;
  /*justify-content: start;*/
  /*border: 1px solid #39A2DB;*/
  /*border-radius: 2rem;*/
  padding-left: 0;
  color: var(--black);
  border: unset !important;
}

#left-tab {
  width: 100%;
  margin: 0 auto 0;
  /*margin: 0 auto 2rem;*/
  display: flex;
  align-items: start;
  /*align-items: center;*/
  /*justify-content: space-between;*/
  justify-content: center !important;
  /*border: 1px solid #39A2DB;*/
  /*border-radius: 2rem;*/
  padding-left: 0;
  color: var(--black);
  border: unset !important;
}

.react-tabs__tab {
  min-width: 75px;
  min-height: 90px;
  padding: 1.5rem 0 0 0;
  list-style: none;
  cursor: pointer;
  text-align: center;
  border: 3px solid transparent;
  border: 3px solid transparent;
}

#left-tab:after {
  content: "" !important;
  position: absolute !important;
  height: 0.35rem !important;
  background-color: var(--gray) !important;
  margin: 3.86rem 0 0 2px !important;
  /*min-width: 75px;*/
  width: 9rem;
  z-index: 1;
  /*transform: translate(-70%);*/
}

.react-tabs__tab--selected {
  position: relative !important;
  display: inline-block !important;
  margin-bottom: 1rem !important;
  text-align: center;
  border: 3px solid transparent;
  border: 3px solid transparent;
  /*background: #39A2DB;*/
}

.react-tabs__tab--selected:after {
  content: "" !important;
  position: absolute !important;
  height: 0.35rem !important;
  background-color: var(--main-color) !important;
  margin: 2.2rem 0 0 0 !important;
  width: 4.5rem;
  left: 50%;
  transform: translate(-50%);
  z-index: 2;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab:hover {
  border: 3px solid transparent;
  border: 3px solid transparent;
  /*border: 1px solid #39A2DB;*/
  /*color: var(--red);*/
  /*background: rgba(50, 224, 196, 0.15);*/
}

.react-tabs__tab:first-child {
  /*border-bottom-left-radius: 2rem;*/
  /*border-top-left-radius: 2rem;*/
}

.react-tabs__tab:last-child {
  /*border-bottom-right-radius: 2rem;*/
  /*border-top-right-radius: 2rem;*/
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
  /*font-size: 2rem;*/
  /*text-align: center;*/
}

.overflow-x-auto {
  width: 90vw;
  overflow-x: auto;
}

/*#slideout_inner {*/
/*  z-index: 9;*/
/*  position: fixed;*/
/*  bottom: 40px;*/
/*  right: -25px;*/
/*  background: var(--main-color);*/
/*  width: 40px;*/
/*  height: 90px;*/
/*  -webkit-transition-duration: 0.3s;*/
/*  -moz-transition-duration: 0.3s;*/
/*  transition-duration: 0.3s;*/
/*  -o-transition-duration: 0.3s;*/
/*  text-align: left;*/
/*  -webkit-border-radius: 0 0 5px 0;*/
/*  -moz-border-radius: 0 0 5px 0;*/
/*  border-radius: 20px 0 0 20px;*/
/*}*/

/*#slideout_inner:hover {*/
/*  right: 0px;*/
/*}*/

/*Slide out v2*/
.slideout {
  background: var(--main-color);
  z-index: 1000;
  position: fixed;
  bottom: 40px;
  left: calc(100% - 21px);
  /*left: calc(100% - 15px);*/
  width: 55px;
  /*width: 45px;*/
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border-radius: 25px 0 0 25px;
  /*border: 1px solid var(--main-color);*/
  -webkit-transition: all, 0.3s;
  transition: all, 0.3s;
}

.arrow-sideout {
  /*padding-top: 3rem;*/
  margin-top: 2.5rem;
  padding-left: 0.2rem;
  height: 1.5rem;
  width: auto;
}

@media (prefers-reduced-motion: reduce) {
  .slideout {
    -webkit-transition: none;
    transition: none;
  }
}

.slideout.show {
  left: calc(100% - 55px);
}

.slideout .spinner {
  /*width: 20px;*/
  width: 25px;
  /*height: 85px;*/
  height: 100px;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
  /*border-right: 1px solid rgba(173, 181, 189, 0.2);*/
}

.slideout .spinner i {
  color: #fff;
}

.slideout .body {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
}

.slideout .body a {
  display: inline-block;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  margin-top: 11px;
}

.home-icon {
  color: #000000;
  width: 20px;
  /*height:20px;*/
  height: 38px;
}

.ticket-icon {
  color: #000000;
  width: 23px;
  /*height:23px;*/
  height: 35px;
}

/*.slideout .body a.light {*/
/*  background: #fff;*/
/*  border: 2px solid #343a40;*/
/*}*/

/*.slideout .body a.dark {*/
/*  background: #343a40;*/
/*  border: 2px solid #fff;*/
/*}*/

/*Over flow*/
/* width */
::-webkit-scrollbar {
  width: 3px !important;
  height: 3px !important;
}

/* Track */
::-webkit-scrollbar-track {
  /*background: var(--white);*/
  background: transparent !important;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  /*background: var(--main-button);*/
  /*background: var(--white);*/
  background: transparent !important;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--main-button-disabled) !important;
  /* background: transparent!important; */
}

/*::-webkit-scrollbar:horizontal {*/
/*  width: 3px!important;*/
/*  height: 3px;*/
/*}*/

::-webkit-scrollbar-thumb:horizontal {
  /*background: transparent;*/
  background: var(--main-button) !important;
  /* background: transparent!important; */
  border-radius: 10px;
}

/*Over flow*/
/* Handle */
/*.right-section::-webkit-scrollbar-thumb {*/
/*  background: transparent;*/
/*  border-radius: 10px;*/
/*}*/

/*.left-section::-webkit-scrollbar-thumb {*/
/*  background: transparent;*/
/*  border-radius: 10px;*/
/*}*/

.input-group-link {
  /*position: relative;*/
  margin: 1rem 0 1rem 0 !important;
}

.icon-input {
  /*z-index: 10;*/
  width: 20px;
  height: auto;
  margin: 0.6rem 1rem 0.2rem 1.5rem !important;
  cursor: pointer;
  position: absolute;
}

.input-login {
  /*padding-left: 3rem!important;*/
  border-radius: 15px !important;
  /*margin-left: -5vw!important;*/
  /*width: 130%!important;*/
}

.logo {
  width: 8rem !important;
  height: auto;
  margin-top: -4.3rem !important;
}

.day-link {
  text-decoration: none;
  color: inherit;
  font-weight: bold;
  /*font-size: 20px;*/
}

.date-link {
  color: var(--gray);
  font-weight: bold !important;
  font-size: 13px;
}

.ml-minushalf1 {
  margin-left: -0.5rem !important;
}

.day-link:hover {
  color: inherit;
  border-bottom: 3px solid var(--main-color);
  padding-bottom: 1.7rem;
}

.back-to-top {
  position: fixed;
  bottom: 25px;
  right: 25px;
  display: none;
  z-index: 3;
}

.back-to-top-mobile {
  position: fixed;
  /*bottom: 75px;*/
  bottom: 25px;
  /*right: 40px;*/
  right: 25px;
  display: none;
  z-index: 4;
}

.mobile-bar {
  position: fixed;
  bottom: 35px;
  z-index: 3;
  display: flex;
  justify-content: center;
  width: 100%;
  /*text-align: center;*/
}
.bg-icon-mobile-bar {
  /*right: 70%!important;*/
  /*transform: translateX(-50%)!important;*/

  background: #d9d9d999 !important;
  border-radius: 30px !important;
  width: 8.9rem;
  padding: 0 0 0.1rem 0.5rem !important;
  /*width: 10rem;*/
  /*padding: .5rem .5rem .5rem 1rem!important;*/
}

.home-icon-mobile-bar {
  color: #fff !important;
  width: auto;
  height: 30px;
  padding-top: 3px;
  padding-right: 3px;
}

.profile-img-mobile-bar {
  width: 2.5rem;
  /*background-color: var(--white);*/
  border-radius: 50%;
  filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.25));
  /*position: absolute;*/
  /*margin: -.3rem 0 0 0;*/
}

.post-icon {
  width: 3rem;
  height: auto;
  margin: 0.2rem 0.5rem 0 0.3rem;
}

.post-icon-circle {
  color: #fff;
  background-color: #000;
  border-radius: 50%;
  width: 3rem;
  height: auto;
  margin: 0.2rem 0.5rem 0 0.3rem;
  padding: 15px;
}

.post-icon-mobile {
  /*position: fixed;*/
  /*bottom: 25px;*/
  /*right: 25px;*/
  /*z-index: 3;*/
}

.camera-day {
  margin-top: 0.15rem;
  margin-left: 1rem;
  cursor: pointer;
}

.image-day {
  margin-right: 1rem;
}

.invite-box-left {
  z-index: 3;
  padding: 0.5rem 0 0.5rem 0.7rem;
  background-color: var(--white) !important;
  border-bottom-left-radius: 30px;
  border-top-left-radius: 30px;
  filter: drop-shadow(-9px 4px 10px rgba(0, 0, 0, 0.1));
}

.invite-box-center {
  /*padding: .5rem .5rem .5rem .5rem;*/
  padding: 2px;
  background-color: var(--white) !important;
  filter: drop-shadow(14px 4px 10px rgba(0, 0, 0, 0.1));
  z-index: 1;
}

.invite-box-right {
  padding: 0.83rem 0.7rem 0.5rem 0.5rem;
  background-color: var(--white) !important;
  border-bottom-right-radius: 30px;
  border-top-right-radius: 30px;
  filter: drop-shadow(14px 4px 10px rgba(0, 0, 0, 0.1));
  z-index: 2;
}

.invite-profile-img {
  cursor: pointer;
  /*width: 2.5rem;*/
  /*height: 2.5rem;*/
  max-width: 2rem;
  width: 90% !important;
  height: auto !important;
  /*padding: .2rem;*/
  /*margin-right: 5px;*/
  margin-top: 0.8rem;
  background-color: var(--white);
  border-radius: 50%;
  filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.2));
}

.invite-profile-img-icon:before {
  cursor: pointer;
  content: "\FF0B";
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.8rem !important;
  height: 1.8rem !important;
  border-radius: 50%;
  border: unset;
  font-size: 30px;
  color: black;
  background-color: var(--white);
  filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.2));
  margin: -1.9rem 0 0 1.3rem;
}

.invite-box-best-deal {
  color: red;
  /*padding: -2px 0 0 5px!important;*/
  /*padding: 0!important;*/
  /*padding: .5rem 0 .5rem .7rem;*/
  background-color: var(--white) !important;
  border-radius: 30px;
  border: 2px solid #000000;
  /*filter: drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.2));*/
  cursor: pointer;
  width: 108px;
  height: 62px;
  /*max-width: 7rem;*/
}

.icon-best-deal {
  margin: 23px 5px 0 0 !important;
}
.text-best-deal {
  margin: 3px 0 0 16px !important;
}

.invite-box-best-deal .small {
  font-size: 11px !important;
  font-weight: bold;
}

.invite-box-best-deal .book {
  font-size: 16px !important;
  font-weight: bold;
  padding-bottom: 3.5px;
}

/*.invite-profile-img:after{*/
/*  content: '\FF0B';*/
/*  position: absolute;*/
/*  cursor: pointer;*/
/*  max-width: 2rem;*/
/*  width: 90%!important;*/
/*  height: auto!important;*/
/*  margin-top: .5rem;*/
/*  background-color: var(--white);*/
/*  border-radius: 50%;*/
/*  filter: drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.2));*/
/*}*/

.invite-profile-img-mobile {
  cursor: pointer;
  max-width: 2rem;
  width: 90% !important;
  height: auto !important;
  /*padding: .2rem;*/
  /*margin-right: 5px;*/
  margin-top: 0.5rem;
  background-color: var(--white);
  border-radius: 50%;
  filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.2));
  object-fit: cover;
}

#friendInvited {
  margin-top: 0.35rem !important;
}
/*.travel-blog-list-400{*/
/*  !*width: 100%!important;*!*/
/*  height: 360px!important;*/
/*  !*height: auto!important;*!*/
/*  object-fit: cover;*/
/*}*/

/*.travel-blog-list-200{*/
/*  height: 180px;*/
/*  object-fit: cover;*/
/*}*/

.travel-blog-list-200-index {
  object-fit: cover;
}

.travel-blog-list-400-index {
  object-fit: cover;
}

.input-link {
  padding-left: 3rem !important;
  border-radius: 15px !important;
  margin-left: -5vw !important;
  width: 130% !important;
}

.input-link-update {
  padding-left: 2.2rem !important;
  border-radius: 15px !important;
  /* border: 2px solid #000!important; */
  border: transparent !important;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.25);
  /*margin-left: -5vw!important;*/
  /*width: 130%!important;*/
  color: #2586cc !important;
  z-index: 1;
}

.input-link-before {
  padding-left: 2.2rem !important;
  border-radius: 15px !important;
  /* border: 2px solid #000!important; */
  border: transparent !important;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.25);
  /*margin-left: -5vw!important;*/
  /*width: 130%!important;*/
  color: #2586cc !important;
  z-index: 1;
}

.input-link-update-input {
  padding-left: 2.2rem !important;
  border-radius: 15px !important;
  /*margin-left: -5vw!important;*/
  /*width: 130%!important;*/
  z-index: 1;
}

.input-group-icon {
  position: absolute;
  z-index: 2;
  /*background-color: transparent;*/
}

.icon-input-update {
  position: absolute;
  z-index: 2;
  z-index: 2;
  width: 20px;
  height: auto;
  margin: 0.6rem 0 0.2rem 0.5rem !important;
  cursor: pointer;
  position: absolute;
}

.icon-input-update-currency {
  position: absolute;
  z-index: 2;
  z-index: 2;
  width: 20px;
  height: auto;
  margin: 0.6rem 0 0.2rem 0.6rem !important;
  cursor: pointer;
  position: absolute;
}

.icon-input-before {
  /* width: 20px;
  height: auto;
  margin: .6rem 0 .2rem 0!important;
  cursor: pointer; */
  position: absolute;
  z-index: 2;
  z-index: 2;
  width: 20px;
  height: auto;
  margin: 0.6rem 0 0.2rem 0.5rem !important;
  cursor: pointer;
  position: absolute;
}

.input-link-desktop {
  padding-left: 3rem !important;
  border-radius: 15px !important;
  margin-left: -2.5vw !important;
  width: 150% !important;
}

/* Medium Devices, Desktops */
/*@media only screen and (max-width : 992px) {*/
/*  .input-link{*/
/*    padding-left: 3rem!important;*/
/*    margin-left: -11vw!important;*/
/*    width: 150%!important;*/
/*  }*/
/*}*/
/*@media (max-width: 1200px){*/
/*  .input-link-desktop{*/
/*    padding-left: 3rem!important;*/
/*    margin-left: -2vw!important;*/
/*    width: 150%!important;*/
/*  }*/
/*}*/
.share-button {
  display: flex;
  justify-content: end;
  padding: 8px 0 0 0 !important;
}

.travel-blog-list-400 {
  height: 500px !important;
  object-fit: cover;
}

.travel-blog-list-200 {
  height: 250px !important;
  object-fit: cover;
}

.overlay-travel-blog-list-200 {
  position: absolute;
  margin-top: -7rem;
  left: 67%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  font-size: 48px;
}

.overlay-travel-blog-list-200-index {
  position: absolute;
  margin-top: -7rem;
  left: 42vw;
  transform: translate(-50%, -50%);
  color: #ffffff;
  font-size: 48px;
}

.search-bar-absolute {
  position: fixed !important;
  width: 40% !important;
  top: 5rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 6;
}

.add-day-bar {
  /*position: fixed!important;*/
  position: absolute !important;
  z-index: 3;
  bottom: 127px;
  /*width: 37%;*/
  right: 31%;
  height: auto;
  display: flex;
  justify-content: flex-end;
}

.post-bar {
  position: fixed !important;
  /*position: absolute!important;*/
  z-index: 2;
  bottom: 24px;
  right: 15px;
  height: auto;
  display: flex;
  justify-content: flex-end;
}

.react-date-picker__calendar {
  z-index: 3 !important;
}

.line-content {
  width: 80%;
}

@media (max-width: 2400px) {
  .button-plus {
    margin: -4rem 0 0 5vw;
  }
}

@media (max-width: 2000px) {
  .button-plus {
    margin: -4rem 0 0 4.8vw;
  }
}

@media (max-width: 1720px) {
  .button-plus {
    margin: -4rem 0 0 4vw;
  }
}

@media (max-width: 1450px) {
  .button-plus {
    margin: -4rem 0 0 3.2vw;
  }

  .add-day-bar {
    /*position: fixed!important;*/
    position: absolute !important;
    z-index: 3;
    bottom: 95px;
    /*width: 37%;*/
    right: 31%;
    height: auto;
    display: flex;
    justify-content: flex-end;
  }
}

@media (max-width: 1200px) {
  .button-plus {
    margin: -4rem 0 0 2.8vw;
  }

  .travel-blog-list-400 {
    height: 450px !important;
    object-fit: cover;
  }

  .travel-blog-list-200 {
    height: 225px !important;
    object-fit: cover;
  }
}

@media (max-width: 992px) {
  .line-content {
    width: 100%;
  }

  .share-button {
    display: flex;
    justify-content: end;
    padding: 8px 15px 0 0 !important;
  }

  .add-day-bar {
    /*position: fixed!important;*/
    position: absolute !important;
    z-index: 3;
    bottom: 140px;
    /*width: 85%;*/
    right: 6%;
    height: auto;
    display: flex;
    justify-content: flex-end;
  }

  .search-bar-absolute {
    position: fixed !important;
    width: 60% !important;
    top: 5rem;
    left: 50%;
    transform: translateX(-60%);
    z-index: 6;
  }

  .profile-img {
    width: 2.5rem;
    background-color: var(--white);
    border-radius: 50%;
    filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.25));
    position: absolute;
    margin: -0.3rem 0 0 0;
  }

  .overlay-travel-blog-list-200 {
    position: absolute;
    margin-top: -9rem;
    left: 80%;
    transform: translateX(-50%);
    color: #ffffff;
    font-size: 48px;
  }

  .overlay-travel-blog-list-200-index {
    position: absolute;
    margin-top: -9rem;
    left: 76%;
    transform: translateX(-50%);
    color: #ffffff;
    font-size: 48px;
  }

  .input-link {
    padding-left: 3rem !important;
    margin-left: -10vw !important;
    width: 150% !important;
  }

  /*.input-link-update{*/
  /*  padding-left: 2rem !important;*/
  /*  !*margin-left: -10vw !important;*!*/
  /*  width: 150% !important;*/
  /*  z-index: 1;*/
  /*}*/

  .input-link-desktop {
    padding-left: 3rem !important;
    margin-left: -2vw !important;
    width: 150% !important;
  }

  .button-plus {
    margin: -4rem 0 0 5vw;
  }

  .travel-blog-list-400 {
    height: 540px !important;
    object-fit: cover;
  }

  .travel-blog-list-200 {
    height: 270px !important;
    object-fit: cover;
  }
  /*.invite-profile-img-mobile{*/
  /*  width: 2.5rem!important;*/
  /*  height: 2.5rem!important;*/
  /*  padding: .2rem;*/
  /*  !*margin-right: 5px;*!*/
  /*  margin-top: 5px;*/
  /*  background-color: var(--white);*/
  /*  border-radius: 50%;*/
  /*  filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.2));*/
  /*}*/
}

.share-modal {
  width: 30% !important;
  height: 50% !important;
  margin: 9rem auto auto auto !important;
  background-color: #ffffff !important;
  border-radius: 30px !important;
  /*left: 50%;*/
  /*transform: translate(-50%);*/
}

@media (max-width: 920px) {
  .share-modal {
    width: 50% !important;
    height: 50% !important;
    margin: 9rem auto auto auto !important;
    background-color: #ffffff !important;
    border-radius: 30px !important;
    /*left: 50%;*/
    /*transform: translate(-50%);*/
  }

  .input-link {
    padding-left: 3rem !important;
    margin-left: -10.5vw !important;
    width: 160% !important;
  }

  /*.input-link-update{*/
  /*  padding-left: 2rem!important;*/
  /*  !*margin-left: -10.5vw!important;*!*/
  /*  width: 160%!important;*/
  /*  z-index: 1;*/
  /*}*/
  .button-plus {
    margin: -4rem 0 0 5vw;
  }

  .travel-blog-list-400 {
    height: 540px !important;
    object-fit: cover;
  }

  .travel-blog-list-200 {
    height: 270px !important;
    object-fit: cover;
  }
  /*.invite-profile-img-mobile{*/
  /*  width: 2.5rem!important;*/
  /*  height: 2.5rem!important;*/
  /*  padding: .2rem;*/
  /*  !*margin-right: 5px;*!*/
  /*  margin-top: 5px;*/
  /*  background-color: var(--white);*/
  /*  border-radius: 50%;*/
  /*  filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.2));*/
  /*}*/
}

/* Extra Small Devices, Phones */
@media (max-width: 767px) {
  .share-modal {
    width: 90% !important;
    height: 50% !important;
    margin: 9rem auto auto auto !important;
    background-color: #ffffff !important;
    border-radius: 30px !important;
    /*left: 50%;*/
    /*transform: translate(-50%);*/
  }

  .overlay-travel-blog-list-200 {
    position: absolute;
    margin-top: -7rem;
    left: 80%;
    transform: translateX(-50%);
    color: #ffffff;
    font-size: 48px;
  }

  .overlay-travel-blog-list-200-index {
    position: absolute;
    margin-top: -7rem;
    left: 80%;
    transform: translateX(-50%);
    color: #ffffff;
    font-size: 48px;
  }

  .input-link {
    padding-left: 3rem !important;
    margin-left: -10vw !important;
    width: 170% !important;
  }
  /*.input-link-update{*/
  /*  padding-left: 2rem!important;*/
  /*  !*margin-left: -10vw!important;*!*/
  /*  width: 170%!important;*/
  /*  z-index: 1;*/
  /*}*/

  .button-plus {
    margin: -4rem 0 0 4vw;
  }

  .travel-blog-list-400 {
    height: 400px !important;
    object-fit: cover;
  }

  .travel-blog-list-200 {
    height: 200px !important;
    object-fit: cover;
  }
}

@media (max-width: 530px) {
  /*.button-plus{*/
  /*  margin: -4rem 0 0 0.4rem;*/
  /*}*/
  .add-day-bar {
    /*position: fixed!important;*/
    position: absolute !important;
    z-index: 3;
    bottom: 85px;
    /*width: 80%;*/
    right: 6%;
    height: auto;
    display: flex;
    justify-content: flex-end;
  }

  .overlay-travel-blog-list-200 {
    position: absolute;
    margin-top: -7rem;
    left: 84%;
    transform: translateX(-50%);
    color: #ffffff;
    font-size: 48px;
  }

  .overlay-travel-blog-list-200-index {
    position: absolute;
    margin-top: -7rem;
    left: 84%;
    transform: translateX(-50%);
    color: #ffffff;
    font-size: 48px;
  }

  .button-plus {
    margin: -4rem 0 0 2vw;
  }

  .travel-blog-list-400 {
    height: 320px !important;
    object-fit: cover;
  }

  .travel-blog-list-200 {
    height: 160px !important;
    object-fit: cover;
  }
}

@media (min-width: 992px) {
  .padding-right-1 {
    padding-right: 2.5rem !important;
  }

  .left-section {
    padding-left: 10% !important;
    position: absolute;
    top: 6rem;
    bottom: 0;
    left: 0;
    overflow-y: auto;
  }

  .right-section {
    padding-right: 10% !important;
    position: absolute;
    top: 5rem;
    bottom: 0;
    right: 0;
    overflow-y: auto;

    /*width: 77%;*/
  }

  .right-content {
    margin: auto;
    /*padding-right: 10%!important;*/
    width: 80% !important;
  }

  .overflow-custom-70 {
    width: 45vw !important;
    overflow-x: auto;
  }
}

.iframe-contain {
  width: 40% !important;
  height: 85% !important;
  margin: 3rem auto auto auto !important;
  background-color: #ffffff !important;
  border-radius: 30px !important;
  /*left: 50%;*/
  /*transform: translate(-50%);*/
}

.share-button-social {
  /*  width: 0%!important;*/
  display: flex !important;
  justify-content: center !important;
}

.post-page {
  /*margin-left: 2%!important;*/
  /*width: 96% !important;*/
  height: 96% !important;
  overflow-y: scroll;
}

.iframe-content {
  border-bottom-left-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
}

body.modal-open {
  overflow: hidden;
  position: fixed;
  width: 100%;
}

@media (max-width: 992px) {
  .iframe-contain {
    width: 90% !important;
    height: 85% !important;
    margin: 3rem auto auto auto !important;
    background-color: #ffffff !important;
    border-radius: 30px !important;
    /*left: 50%;*/
    /*transform: translate(-50%);*/
  }

  .right-section {
    padding-right: unset !important;
    position: absolute;
  }

  .overflow-custom-70 {
    width: 90vw;
    overflow-x: auto;
  }
}

.trip-description {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-96 {
  padding-left: 5rem !important;
  font-size: 96px;
}

.text-96-index {
  padding-left: 5rem !important;
  font-size: 89px;
}

.padding-left-logo {
  padding-left: 0 !important;
}

@media (max-width: 768px) {
  .title-nav {
    position: absolute;
    top: 1.65rem;
    left: 30% !important;
    transform: translateY(-50%);
  }

  .pmx-sm-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .pmx-sm-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (max-width: 992px) {
  .text-96 {
    padding-left: 0 !important;
    width: 100%;
    font-size: 38px;
    font-weight: 700;
  }

  .text-96-index {
    padding-left: 0 !important;
    width: 100%;
    font-size: 38px;
    font-weight: 700;
  }

  .right-section {
    padding: 0 !important;
    position: absolute;
    top: 5rem;
    bottom: 0;
    right: 0;
    overflow-y: auto;
  }
}

.text-36 {
  font-size: 38px;
  font-weight: 700;
}

.anchor {
  position: absolute;
  margin-top: -15%;
  visibility: hidden;
}

.padding-right-2px {
  padding-right: 2px !important;
  padding-left: 0 !important;
}

.padding-left-2px {
  padding-right: 0 !important;
  padding-left: 2px !important;
}

.padding-right-img-1 {
  padding-right: 2px !important;
  padding-left: 0 !important;
}

.padding-left-img-2 {
  padding-right: 0 !important;
  padding-left: 2px !important;
}

.tox-tinymce {
  border: unset !important;
  margin-left: -1rem !important;
}

.fixed-width-tab {
  width: 200px !important;
}

#tripDestination,
#contentDestination,
#tripName {
  font-weight: bold !important;
  font-size: 20px;
}

.font-10 {
  font-size: 12px;
}

.modelComingSoon {
  width: 80%;
  max-width: 350px;
  height: auto;
  margin: auto;
  padding-top: 4rem;
  filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.25));
}

.ReactModalPortal {
  position: absolute;
  z-index: 9999;
}

.nav-mobile {
  position: fixed;
  z-index: 7;
  width: 100%;
}
.copy-right-desktop {
  margin-left: 5%;
  /*transform: translate(-50%);*/
}

.copy-right-mobile {
  left: 50%;
  transform: translate(-50%);
}
.text-red {
  color: red;
}

.new-text {
  color: #2936ff;
  font-weight: 700;
  font-size: 32px;
  line-height: 33px;
}

.title-nav {
  position: absolute;
  top: 1.65rem;
  left: 50%;
  transform: translateY(-50%);
}

.status-text {
  background-color: var(--gray);
  padding: 5px 10px 5px 10px;
  margin: auto 0;
}

.react-date-picker__wrapper {
  border: unset !important;
  padding-top: 5px;
}

.nav-icon {
  color: #000;
  width: 2rem;
  height: auto;
  margin: 0.2rem 0.5rem 0 0.3rem;
}

.bg-transparent-white {
  --bs-bg-opacity: 0.35;
  background-color: #d9d9d982 !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: #fff !important;
}

/*.prev{*/
/*  position: absolute;*/
/*  left: 0;*/
/*  height: 3.45rem;*/
/*  margin-top: 1rem;*/
/*}*/
/*.next{*/
/*  position: absolute;*/
/*  right: 0;*/
/*  height: 3.45rem;*/
/*  margin-top: 1rem;*/
/*}*/

.center-absolute {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.center-absolute-title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: -1.8rem;
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  /*z-index: 1;*/
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  text-align: center;
  z-index: 1030 !important;
}

/*.sidenav a {*/
/*  padding: 8px 8px 8px 32px;*/
/*  text-decoration: none;*/
/*  font-size: 25px;*/
/*  color: #fff;*/
/*  display: block;*/
/*  transition: 0.3s;*/

/*}*/

/*.sidenav a:hover{*/
/*  color: #f1f1f1;*/
/*}*/

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
  color: #0f0f0f;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}

.post-button-bar {
  position: fixed;
  top: 4.5rem;
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin-left: 3%;
}

/*.post-day-button{*/
/*  position: fixed;*/
/*  !*top: 4.5rem;*!*/
/*  display: flex;*/
/*  justify-content: end;*/
/*  width: 95%;*/
/*  margin-left: 3%;*/
/*}*/

.add-day-button {
  width: 70px;
  height: 70px;
  /*box-shadow: 0 4px 15px rgba(0, 0, 0, 0.25);*/
  border-radius: 50% !important;
  --bs-btn-color: #000000 !important;
  --bs-btn-bg: rgba(126, 126, 126, 0.33) !important;
  --bs-btn-border-color: transparent !important;
  --bs-btn-hover-color: #000000 !important;
  --bs-btn-hover-bg: rgba(126, 126, 126, 0.63) !important;
  --bs-btn-hover-border-color: transparent !important;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: var(--main-color) !important;
  --bs-btn-active-bg: var(--gray-ticket) !important;
  --bs-btn-active-border-color: var(--white-ticket) !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--white-ticket) !important;
  --bs-btn-disabled-bg: var(--main-button-disabled) !important;
  --bs-btn-disabled-border-color: var(--main-button-disabled) !important;
}

.add-activity-button {
  /*width: 70px;*/
  height: 70px;
  /*box-shadow: 0 4px 15px rgba(0, 0, 0, 0.25);*/
  border-radius: 60px !important;
  --bs-btn-color: #000000 !important;
  --bs-btn-bg: rgba(126, 126, 126, 0.33) !important;
  --bs-btn-border-color: transparent !important;
  --bs-btn-hover-color: #000000 !important;
  --bs-btn-hover-bg: rgba(126, 126, 126, 0.63) !important;
  --bs-btn-hover-border-color: transparent !important;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: var(--main-color) !important;
  --bs-btn-active-bg: var(--gray-ticket) !important;
  --bs-btn-active-border-color: var(--white-ticket) !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--white-ticket) !important;
  --bs-btn-disabled-bg: var(--main-button-disabled) !important;
  --bs-btn-disabled-border-color: var(--main-button-disabled) !important;
}

.add-day-post-button {
  width: 70px;
  height: 70px;
  /*box-shadow: 0 4px 15px rgba(0, 0, 0, 0.25);*/
  border-radius: 50% !important;
  --bs-btn-color: #000000 !important;
  --bs-btn-bg: #2a37ff !important;
  --bs-btn-border-color: #2a37ff !important;
  --bs-btn-hover-color: #000000 !important;
  --bs-btn-hover-bg: rgba(42, 55, 255, 0.5) !important;
  --bs-btn-hover-border-color: transparent !important;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: var(--main-color) !important;
  --bs-btn-active-bg: var(--gray-ticket) !important;
  --bs-btn-active-border-color: var(--white-ticket) !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000 !important;
  --bs-btn-disabled-bg: var(--main-button-disabled) !important;
  --bs-btn-disabled-border-color: var(--main-button-disabled) !important;
}

.post-button-blue {
  width: 40px;
  height: 40px;
  font-size: 25px !important;

  /*box-shadow: 0 4px 15px rgba(0, 0, 0, 0.25);*/
  border-radius: 50% !important;
  --bs-btn-color: #000000 !important;
  --bs-btn-bg: #2a37ff !important;
  --bs-btn-border-color: #2a37ff !important;
  --bs-btn-hover-color: #000000 !important;
  --bs-btn-hover-bg: rgba(42, 55, 255, 0.5) !important;
  --bs-btn-hover-border-color: transparent !important;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: var(--main-color) !important;
  --bs-btn-active-bg: #2a37ff !important;
  --bs-btn-active-border-color: #2a37ff !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000 !important;
  --bs-btn-disabled-bg: var(--main-button-disabled) !important;
  --bs-btn-disabled-border-color: var(--main-button-disabled) !important;
}

.post-button-blue-mobile {
  width: 45px;
  height: 45px;
  font-size: 28px !important;
  margin-top: -5px;
  /*box-shadow: 0 4px 15px rgba(0, 0, 0, 0.25);*/
  border-radius: 50% !important;
  --bs-btn-color: #000000 !important;
  --bs-btn-bg: #2a37ff !important;
  --bs-btn-border-color: #2a37ff !important;
  --bs-btn-hover-color: #000000 !important;
  --bs-btn-hover-bg: rgba(42, 55, 255, 0.5) !important;
  --bs-btn-hover-border-color: transparent !important;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: var(--main-color) !important;
  --bs-btn-active-bg: #2a37ff !important;
  --bs-btn-active-border-color: #2a37ff !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000 !important;
  --bs-btn-disabled-bg: var(--main-button-disabled) !important;
  --bs-btn-disabled-border-color: var(--main-button-disabled) !important;
}

.circle-button-yellow {
  width: 40px;
  height: 40px;
  font-size: 25px !important;
  margin: 2.1rem 0 0 1rem;

  /*box-shadow: 0 4px 15px rgba(0, 0, 0, 0.25);*/
  border-radius: 50% !important;
  --bs-btn-color: #000000 !important;
  --bs-btn-bg: #ffd600 !important;
  --bs-btn-border-color: #ffd600 !important;
  --bs-btn-hover-color: #000000 !important;
  --bs-btn-hover-bg: #cfbc5e !important;
  --bs-btn-hover-border-color: transparent !important;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: var(--main-color) !important;
  --bs-btn-active-bg: #ffd600 !important;
  --bs-btn-active-border-color: #ffd600 !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000 !important;
  --bs-btn-disabled-bg: var(--main-button-disabled) !important;
  --bs-btn-disabled-border-color: var(--main-button-disabled) !important;
}

.icon-circle-button-yellow {
  margin: -1rem 0 0 -0.8rem;
  font-size: 18px;
}

.circle-button {
  width: 40px;
  height: 40px;
  font-size: 12px !important;
  margin: 0 0 0 0.5rem;
  padding: 0 0.5rem 0 0.5rem;

  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 50% !important;
  --bs-btn-color: #000000 !important;
  --bs-btn-bg: #fff !important;
  --bs-btn-border-color: transparent !important;
  --bs-btn-hover-color: #000000 !important;
  --bs-btn-hover-bg: rgba(126, 126, 126, 0.33) !important;
  --bs-btn-hover-border-color: transparent !important;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: var(--main-color) !important;
  --bs-btn-active-bg: #bbb8b8 !important;
  --bs-btn-active-border-color: #bbb8b8 !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000 !important;
  --bs-btn-disabled-bg: var(--main-button-disabled) !important;
  --bs-btn-disabled-border-color: var(--main-button-disabled) !important;
}

.icon-circle-button {
  width: 30px;
  margin: -0.1rem 0 0 -0.5rem;
}

.icon-post {
  margin: 0 0 18px -5px !important;
}
.icon-post-mobile {
  width: 30px;
  margin: -1.2rem 0 0 -0.3rem !important;
}

.react-calendar {
  border: unset !important;
  border-radius: 27px !important;
  box-shadow: 0px 4px 45px 0px rgba(0, 0, 0, 0.25) !important;
  padding: 0.5rem 1rem 0.5rem 1rem;
}

.react-calendar__tile {
  line-height: 1.9 !important;
}

.react-calendar__tile--active {
  background: rgba(251, 63, 74, 1) !important;
  border-radius: 50%;
  width: 50px !important;
  height: 45px !important;
  color: #fff !important;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.react-calendar__tile:hover {
  background: rgba(251, 63, 74, 1) !important;
  border-radius: 50%;
  width: 50px !important;
  height: 45px !important;
  color: #fff !important;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.react-calendar__tile--now {
  border-radius: 50% !important;
  width: 50px !important;
  height: 45px !important;
  background: transparent !important;
}

.react-date-picker__inputGroup {
  display: none !important;
}

.fulldate-calendar {
  font-weight: 500;
  margin-left: -0.8rem !important;
  cursor: pointer;
}

.date-input-box {
  /* border: #000 1px solid!important; */
  min-width: 120px;
  width: 60% !important;
  /*margin: 1.5rem 0 1.5rem 0!important;*/
  margin: -1.5rem 0 0 -0.2rem;
  padding: 0.5rem;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.25);
  border-radius: 29px !important;
  color: #2586cc !important;
}
.date-input-box-text {
  padding: 0 0 0 1.3rem !important;
}

.fade-out {
  opacity: 1;
  animation: fadeOut 1s ease-in-out forwards;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

.invite-bar-text-small {
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}

.dropdown-menu {
  display: none; /* Check for any conflicting styles */
  visibility: hidden;
}

.navbar {
  position: relative; /* Required for absolute positioning of dropdown */
}

/* .inline-cell {
  flex: 1;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  border: 1px solid #ddd;
  margin: 0 2px;
  position: relative;
} */

.inline-cell .number {
  display: inline-block;
  min-width: 30px;
  min-height: 30px;
  line-height: 30px;
  border-radius: 50%; /* Makes the element circular */
  text-align: center;
  font-size: 14px;
  /* font-weight: bold; */
}

.inline-cell.today .number {
  background-color: red;
  border: 2px solid red;
}

.calendar .body {
  display: flex;
  flex-wrap: wrap;
  /* gap: 10px; */
  width: 100%;
}

.calendar .cell {
  flex: 1 0 14.28%;
  max-width: 14.28%;
  /* height: 80px; */
  display: flex;
  align-items: center;
  justify-content: center;
  border: unset;
  background-color: transparent;
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
}
